import { useParams } from "react-router-dom";
import Navbar from "../../components/navbar";
import './style.css';
import Star from '../../assets/star.png';
import Produto from '../../components/produto/lista';
import Footer from '../../components/footer';
import { useContext, useEffect, useState } from "react";
import api from '../../services/api';
import ProdutoModal from '../../components/produto/modal';
import FavVazio from '../../assets/favorito.png';
import FavCheio from '../../assets/favorito2.png';
import { CartContext } from "../../contexts/cart";
import LoadingSpinner from "../../components/loading-spinner"; 

function Cardapio() {
    const { cart, setEntregaCart, idEstabelecimentoCart, setIdEstabelecimentoCart,
        horariosFuncionamento, setHorariosFuncionamento, verificarAbertura, aberto, setAberto} = useContext(CartContext);
    const { id } = useParams();
    const [nome, setNome] = useState('');
    const [endereco, setEndereco] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUF] = useState('');
    const [avaliacao, setAvaliacao] = useState(0);
    const [foto, setFoto] = useState('');
    const [entrega, setEntrega] = useState(0);
    const [minimo, setMinimo] = useState(0);
    const [qtd, setQtd] = useState(0);
    const [favorito, setFavorito] = useState(false);
    const [idFavorito, setIdFavorito] = useState(0);
    const [id_produto, setId_produto] = useState(0);
    const [categorias, setCategorias] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [isProdutoOpen, setIsProdutoOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    function openSidebar(){
        const event = new CustomEvent('openSidebar');
        window.dispatchEvent(event);
    }

    useEffect(() => {
        setLoading(true);
        api.get(`/v1/estabelecimentos/${id}`)
            .then(response => {
                const data = response.data[0];
                setNome(data.nome);
                setEndereco(data.endereco);
                setNumero(data.numero);
                setComplemento(data.complemento);
                setBairro(data.bairro);
                setCidade(data.cidade);
                setUF(data.uf);
                setAvaliacao(data.med_avaliacao);
                setFoto(data.url_foto);
                setEntrega(data.vl_taxa_entrega);
                setMinimo(data.vl_min_pedido);
                setQtd(data.qtd_avaliacao);
                setFavorito(data.id_favorito > 0);
                setIdFavorito(data.id_favorito);
                setHorariosFuncionamento(data.horarios_funcionamento);
                localStorage.setItem('sessionNomeEstabelecimento', data.nome)
                localStorage.setItem('sessionIdEstabelecimento', data.id_estabelecimento)
            })
            .catch(err => {
                console.log(err);
            });

        api.get(`/v1/cardapios/${id}`)
            .then(response => {
                let categoriasUnica = response.data.map(item => item.categoria);
                categoriasUnica = categoriasUnica.filter((itemArray, i, arrayCompleto) => {
                    return arrayCompleto.indexOf(itemArray) === i;
                });
                setCategorias(categoriasUnica);
                setProdutos(response.data);
                
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => setLoading(false));
    }, [id]); 

    function openModalProduto(id_prod) {
        if (cart.length > 0 && idEstabelecimentoCart !== id && idEstabelecimentoCart > 0) {
            alert('Já existem produtos de outro estabelecimento na sacola.');
            return;
        }
        setId_produto(id_prod);
        setEntregaCart(entrega);
        setIdEstabelecimentoCart(id);
        setIsProdutoOpen(true);
    }

    function closeModalProduto() {
        setIsProdutoOpen(false);
    }

    function Favoritar() {
        api.post('/v1/estabelecimentos/favoritos', { id_estabelecimento: id })
            .then(response => {
                setFavorito(true);
                setIdFavorito(response.data.id_favorito);
            })
            .catch(err => {
                console.log(err);
            });
    }

    function RemoverFavorito() {
        api.delete(`/v1/estabelecimentos/favoritos/${idFavorito}`)
            .then(() => {
                setFavorito(false);
            })
            .catch(err => {
                console.log(err);
            });
    }      

    return (
        <div className="container-fluid mt-page cardapio">
            <Navbar />

            {loading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <ProdutoModal isOpen={isProdutoOpen}
                                  onRequestClose={closeModalProduto}
                                  id_produto={id_produto} />

                    <div className="row col-lg-8 offset-lg-2">
                        <div className="col-12">
                            <img className="img-fluid rounded img-estab-cardapio" src={foto} alt="Estabelecimento" />
                        </div>

                        <div className="col-12 mt-4">
                            <div className="d-flex justify-content-between">
                                <h2>{nome}</h2>
                                <div className="favorito">
                                    {favorito ? <img src={FavCheio} alt="Remover Favorito" onClick={RemoverFavorito} /> 
                                              : <img src={FavVazio} alt="Favoritar" onClick={Favoritar} />}
                                </div>
                            </div>

                            <div className="classificacao">
                                <span>{endereco} - {complemento} - {bairro} - {cidade} - {uf}</span>
                            </div>

                            <div className="classificacao">
                                <img src={Star} alt="Avaliação" />
                                <span className="ms-1">{Number(avaliacao).toFixed(1)}</span>
                                <span className="ms-3">{qtd} avaliações</span>
                            </div>

                            <div className="classificacao mt-3 ">                    
                                <span><b>Taxa de entrega: </b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(entrega)}</span>
                                <span className="ms-3"><b>Pedido mínimo: </b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(minimo)}</span>
                                <span className="ms-5  "><b>Status: </b>{verificarAbertura(horariosFuncionamento) ? <span className="badge rounded-pill bg-success pt-1">Aberto</span> :
                                <span className="badge rounded-pill bg-danger pt-1 ">Fechado</span> }</span>
                            </div>

                          
                            {/* Bloco para exibir os horários de funcionamento */}
                            <div className="classificacao mt-2">
                                <h6><b>Horários de Funcionamento:</b></h6>
                                <details>
                                    <summary>Clique </summary>
                                    <ul>
                                        {horariosFuncionamento.map((horario, index) => (
                                            <li key={index}>
                                                {horario.dia_semana}: {horario.h_abertura} - {horario.h_fechamento}
                                            </li>
                                        ))}
                                    </ul>
                                </details>
                            </div>

                        </div>

                        {categorias.map(categoria => (
                            <div key={categoria} className="row mt-4">
                                <div className="mb-3">
                                    <h5>{categoria}</h5>
                                </div>
                                {produtos.map(produto => (
                                    produto.categoria === categoria && (
                                        <Produto key={produto.id_produto}
                                                 nome={produto.nome}
                                                 descricao={produto.descricao}
                                                 id_produto={produto.id_produto}
                                                 url_foto={produto.url_foto}
                                                 vl_produto={produto.vl_produto}
                                                 vl_promocao={produto.vl_promocao}
                                                 onClickProduto={openModalProduto} />
                                    )
                                ))}
                            </div>
                        ))}
                      </div>
             
                    <div className="row col-lg-4 offset-lg-4 col-sm-4  offset-sm-4">
                    <button className="btn btn-lg btn-danger mt-4" onClick={openSidebar}> 
                        Minha Sacola
                    </button>
                    </div>  

                <Footer />
                </>
            )}
        </div>
    );
}

export default Cardapio;
