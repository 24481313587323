import { useEffect, useState } from "react";
import { loadMercadoPago } from "@mercadopago/sdk-js";
import api from "../../../services/api";
import './cartao.css';
import { hide } from "react-modal/lib/helpers/ariaAppHider";

function Cartao(props) {
  const [progress, setProgress] = useState(0);  
  const [deviceId, setDeviceId] = useState("");
  let cardFormInstance = null;  

  useEffect(() => {
    const loadDeviceId = () => {
      const script = document.createElement("script");
      script.src = `https://www.mercadopago.com/v2/security.js`;
      script.setAttribute("view", "checkout");
      script.setAttribute("output","deviceId");
      document.body.appendChild(script);
  
      setTimeout(() => {
        const deviceIdInput = document.getElementById('deviceId');
    if (deviceIdInput) {
      localStorage.setItem('deviceId', deviceIdInput.value);
    }       
      }, 5000); // Aguarda 5s para garantir que o script carregou
    };
  
    loadDeviceId();
  
    // return () => {
    //   const scripts = document.querySelectorAll('script[src*="mercadopago.com/v2/security.js"]');
    //   scripts.forEach((script) => script.remove()); // Remove o script ao desmontar
    // };
  }, []);    
  
  useEffect(() => {
    const initMercadoPago = async () => {
      await loadMercadoPago();
      const mp = new window.MercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY) 

      cardFormInstance = mp.cardForm({
        amount: String(props.amount),
        iframe: true,
        form: {
          id: "form-checkout",
          cardNumber: { id: "form-checkout__cardNumber", placeholder: "Número do cartão" },
          expirationDate: { id: "form-checkout__expirationDate", placeholder: "MM/YY" },
          securityCode: { id: "form-checkout__securityCode", placeholder: "Código de segurança" },
          cardholderName: { id: "form-checkout__cardholderName", placeholder: "Titular do cartão" },
          issuer: { id: "form-checkout__issuer", placeholder: "Banco emissor" },
          installments: { id: "form-checkout__installments", placeholder: "" },
          identificationType: { id: "form-checkout__identificationType", placeholder: "Tipo de documento" },
          identificationNumber: { id: "form-checkout__identificationNumber", placeholder: "Número do documento" },
          cardholderEmail: { id: "form-checkout__cardholderEmail", placeholder: "E-mail" },
        },
        callbacks: {
          onFormMounted: (error) => {
            if (error) console.warn("Erro ao montar o formulário:", error);
          },
          onSubmit: async (event) => {
            event.preventDefault();
            startProgress();

            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
            } = cardFormInstance.getCardFormData();

            try {
              const response = await api.post("/v1/process_payment", {
                token,
                issuer_id,
                payment_method_id,
                transaction_amount: Number(amount),
                installments: Number(1),
                description: props.description,
                payer: {
                  email,
                  identification: { type: identificationType, number: identificationNumber }
                },
                notification_url: process.env.REACT_APP_NOTIFICATION_URL,
                statement_descriptor: props.statement_descriptor,
                external_reference :props.external_reference,
                additional_info: {
                  payer: {
                    first_name: props.first_name,
                    last_name: props.last_name,
                    phone: { area_code: props.area_code, number: props.number },
                    address: {
                      zip_code: props.zip_code,
                      street_name: props.street_name,
                      street_number: props.street_number
                    }
                  }
                },       
                id_pagador: props.id_pagador,
                id_estabelecimento: props.id_estabelecimento              
              },{
                headers: { "X-meli-session-id" : localStorage.getItem('deviceId')} // Enviando o device_id no header
              }            
              
            );

              if (response.data.status === "approved") {
                props.onPaymentStatusChange("approved");
                props.onPaymentIDChange(response.data.id);
              }

              if (response.data.status === "in_process") {
                const intervalId = setInterval(async () => {
                  const statusResponse = await api.get(`/v1/payment_status/${response.data.id}`);                 
                  
                  if (statusResponse.data.status === "approved") {             
                    clearInterval(intervalId); // Para o intervalo quando o pagamento for aprovado              
                    props.onPaymentStatusChange("approved"); 
                    props.onPaymentIDChange(response.data.id);
                  }
                }, 5000); // Verifica a cada 5 segundos
                             
              }
            } catch (error) {
              console.error("Erro ao processar o pagamento:", error);
            } finally {
              setProgress(100);
            }
          },
        },
      });
    };

    initMercadoPago();

    return () => {
      if (cardFormInstance) {
        cardFormInstance.unmount();
        cardFormInstance = null;
      }
    };
  }, [props.amount, deviceId]); 

  const startProgress = () => {
    let currentProgress = 0;
    const increment = 90 / 10;
    const interval = 100;

    const intervalId = setInterval(() => {
      currentProgress += increment;
      setProgress(currentProgress);
      if (currentProgress >= 90) clearInterval(intervalId);
    }, interval);
  };

 
  return (
    <>
      <input type="hidden" id="deviceId" value={deviceId}></input>
      <form id="form-checkout">
        <div id="form-checkout__cardNumber" className="container"></div>
        <div id="form-checkout__expirationDate" className="container"></div>
        <div id="form-checkout__securityCode" className="container"></div>
        <input type="text" id="form-checkout__cardholderName" placeholder="Titular do cartão" />
        <select id="form-checkout__issuer"></select>
        <select id="form-checkout__installments" className="oculto" disabled></select>
        <select id="form-checkout__identificationType"></select>
        <input type="text" id="form-checkout__identificationNumber" placeholder="Número do documento" />
        <input type="email" id="form-checkout__cardholderEmail" placeholder="E-mail" />
        <button type="submit" id="form-checkout__submit">Pagar</button>
        <progress className="progress-bar" value={progress} max="100">Carregando...</progress>
      </form>
    </>
  );
}

export default Cartao;
