import { useEffect, useState } from "react";
import Navbar from '../../components/navbar';
import Categoria from "../../components/categoria";
import Banner from "../../components/banner";
import Estabelecimento from "../../components/estabelecimento";
import Footer from "../../components/footer";
import api from '../../services/api';
import LoadingSpinner from '../../components/loading-spinner'; // Adicionei LoadingSpinner para feedback visual

function Home() {
    const [categorias, setCategorias] = useState([]);
    const [banners, setBanners] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [destaques, setDestaques] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [cidadeSelecionada, setCidadeSelecionada] = useState(localStorage.getItem('sessionCodCidade') || "");
    const [loading, setLoading] = useState(true);
    const [mensagem, setMensagem] = useState("");
    const [erro, setErro] = useState(null); // Adicionado estado para erros

    useEffect(() => {
        // Buscar lista de cidades
        api.get('v1/cidades')
            .then(response => {
                setCidades(response.data);
            })
            .catch(err => {
                console.error("Erro ao carregar cidades:", err);
                setErro("Erro ao carregar cidades."); // Atualiza o estado de erro
                setMensagem("Erro ao carregar cidades.");
            })
            .finally(() => {
                if (!cidadeSelecionada) {
                    setLoading(false); // Garante que loading seja falso em erro
                }
            });
    }, []);

    useEffect(() => {
        if (!cidadeSelecionada) return;

        setLoading(true);
        Promise.all([
            api.get(`v1/categorias?cod_cidade=${cidadeSelecionada}`),
            api.get(`v1/banners?cod_cidade=${cidadeSelecionada}`),
            api.get(`v1/destaques?cod_cidade=${cidadeSelecionada}`)
        ])
            .then(([categoriasRes, bannersRes, destaquesRes]) => {
                setCategorias(categoriasRes.data);
                setBanners(bannersRes.data);

                const gruposUnico = [...new Set(destaquesRes.data.map(grupo => grupo.descricao))];
                setGrupos(gruposUnico);
                setDestaques(destaquesRes.data);
            })
            .catch(err => {
                console.error("Erro ao carregar dados:", err);
                setErro("Erro ao carregar dados."); // Atualiza o estado de erro
                setMensagem("Erro ao carregar dados.");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [cidadeSelecionada]);

    const atualizarCidadeSelecionada = (cidade) => {
        setCidadeSelecionada(cidade);
        localStorage.setItem('sessionCodCidade', cidade);
    };

    function handleSelecionarCidade(event) {
        const cidade = event.target.value;
        if (cidade) {
            atualizarCidadeSelecionada(cidade);
        }
    }

    return (
        <div className="container-fluid mt-page">
            <Navbar />

             {((!cidadeSelecionada || cidadeSelecionada === "null") ? (
                <div className="row">
                    <div className="justify-content-center text-center ">
                        <h2>Selecione sua cidade</h2>
                        {erro && <p style={{ color: "red" }}>{erro}</p>} {/* Exibe a mensagem de erro */}
                        <select onChange={handleSelecionarCidade} defaultValue="">
                            <option value="" disabled>Escolha uma cidade</option>
                            {cidades.map(cidade => (
                                <option key={cidade.cod_cidade} value={cidade.cod_cidade}>
                                        {cidade.cidade} - {cidade.uf}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            
             ) : (
                <>
                    <div className="row justify-content-center text-center">
                        {categorias.map(categoria => (
                            <Categoria 
                                key={categoria.id_categoria} 
                                url_imagem={categoria.foto}
                                descricao={categoria.categoria}
                                id_categoria={categoria.id_categoria} 
                            />
                        ))}
                    </div>

                    <div className="row justify-content-center text-center mt-5 m-2">
                        {banners.map(banner => (
                            <Banner 
                                key={banner.id_banner} 
                                url_imagem={banner.foto}
                                descricao={banner.descricao}
                                id_banner={banner.id_banner} 
                            />
                        ))}
                    </div>

                    {grupos.map(grupo => (
                        <div key={grupo} className="row mt-5 m-2">
                            <h4>{grupo}</h4>
                            {destaques.map(destaque => (
                                destaque.descricao === grupo ? (
                                    <Estabelecimento 
                                        key={destaque.id_estabelecimento}
                                        url_imagem={destaque.url_logo}
                                        nome={destaque.nome}
                                        avaliacao={destaque.avaliacao}
                                        categoria={destaque.categoria}
                                        id_estabelecimento={destaque.id_estabelecimento} 
                                    />

                                ) : null
                            ))}
                        </div>
                    ))}                     
                </>
             ))}       
             
            <Footer />  
        </div>
    );
}

export default Home;
