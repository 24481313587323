import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from '../../contexts/cart';
import Navbar from '../../components/navbar';
import Produto from '../../components/produto/sacola';
import api from '../../services/api';
import Cartao from "../../components/pagamento/cartao/cartao.jsx"; 
import Pix from "../../components/pagamento/pix/pix.jsx";
import Dinheiro from "../../components/pagamento/dinheiro/dinheiro.jsx"; 
import Lottie from "react-lottie";
import animationData from '../../assets/ani_ok.json'
import { v4 as uuidv4 } from 'uuid';

function Checkout() {
    const navigate = useNavigate();
    const { cart, subtotalCart, descontoCart, cupomCart, entregaCart, totalCart, idEstabelecimentoCart,
        idCupomCart, setCart, setIdCupomCart } = useContext(CartContext);
    const [vl_troco, setVl_troco] = useState('');
    const [enderecos, setEnderecos] = useState([]);
    const [endereco, setEndereco] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUF] = useState('');
    const [cep, setCEP] = useState('');
    const [codCidade, setCodCidade] = useState('');
    const [paymentStatus, setPaymentStatus] = useState("pending");
    const [tipo_pag, setTipo_pag] = useState(null);
    const [id_pag, setId_pag] = useState('');
    const [animationState, setAnimationState] = useState({isStopped: false, isPaused: false})
    const [avisoEndereco, setAvisoEndereco] = useState(false);
    const [VisibleFinalizar, setVisibleFinalizar]= useState(false);

    

    // Função para atualizar o status do pagamento
  function handlePaymentStatusChange(status) {
    setPaymentStatus(status);
    setVisibleFinalizar(true);
   }  
  function handlePaymentIDChange(id) {
     setId_pag(id);
   }  
   function handleVisibleFinalizar(e) {
    if (e = true) {
      setVisibleFinalizar(true);
    } else {
      setVisibleFinalizar(false);
    }
   }
   function handleTrocoChange(troco) {
     setVl_troco(troco);
   }    
  

    const defaultOptions = {
        loop: false,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }}   

    useEffect(() => {       

        if (cart.length === 0) {
            navigate('/');
            setAnimationState(animationState.isPaused)
            return;
        }
        api.get('v1/usuarios/enderecos', {
            params: {
                cod_cidade: localStorage.getItem('sessionCodCidade')
            }
        })
            .then(response => setEnderecos(response.data))
            .catch(err => console.log(err));
    }, []);   

    useEffect(() => {
        if (cart.length === 0) {
            navigate('/');
        }
    }, [cart]);

    function FinalizarPedido() {        
       
            api.post('v1/pedidos', {
                id_estabelecimento: idEstabelecimentoCart,
                id_cupom: idCupomCart ?? 0,
                vl_taxa_entrega: entregaCart,
                vl_desconto: descontoCart,
                vl_total: totalCart,
                vl_troco,
                endereco,
                complemento,
                bairro,
                cidade,
                uf,
                cep,
                cod_cidade: codCidade,
                itens: cart,
                id_pag,
                idCupomCart 
            })
            .then(pedidoResponse => {
                if (pedidoResponse.data) {
                    sessionStorage.removeItem('sessionCart');
                    setCart([]);
                    setIdCupomCart(0);
                    navigate('/pedidos');
                } else {
                    alert('Erro ao enviar o pedido');
                }
            })
            .catch(err => console.log(err));      
    }       
    
    function SelecionarEndereco(end) {
        setEndereco(end.endereco);
        setComplemento(end.complemento);
        setBairro(end.bairro);
        setCidade(end.cidade);
        setUF(end.uf);
        setCEP(end.cep);
        setCodCidade(end.cod_cidade);
    }
    const TipoPagAtualizar = (novoTipo) => {
        setTipo_pag(null); // Limpa o valor anterior
        setTipo_pag(novoTipo); // Define o novo valor
    }
    return (
        <div className="container-fluid mt-page">
            <Navbar />

            <div className="row col-lg-6 offset-lg-3">
                <h2 className="mt-2">Finalizar Pedido</h2>
                <div className="row mt-3">
                    {cart.map(prod => (
                        <div key={prod.id_carrinho}>
                            <Produto
                                nome={prod.nome}
                                valor_total={prod.vl_unit * prod.qtd}
                                qtd={prod.qtd}
                                valor_unit={prod.vl_unit}
                                id_carrinho={prod.id_carrinho}
                                url_foto={prod.url_foto}
                                detalhes={prod.detalhes}
                            />
                        </div>
                    ))}
                </div>

                <div className="mt-5 mb-3">
                    <h4>Endereço de Entrega</h4>
                </div>
                <ul className="list-group list-group-flush">
                    {enderecos.map(endereco => (
                        <li className="list-group-item p-3" key={endereco.id_endereco}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="enderecoEntrega"
                                id={`enderecoEntrega${endereco.id_endereco}`}
                                onClick={() => {
                                    SelecionarEndereco(endereco)
                                    setAvisoEndereco(false)
                                }}
                            />
                            <label className="form-check-label ms-2" htmlFor={`enderecoEntrega${endereco.id_endereco}`}>
                                <b>{endereco.endereco} {endereco.complemento ? ` - ${endereco.complemento}` : null}</b>
                                <small className="d-block">{endereco.cidade} - {endereco.uf}</small>
                            </label>
                        </li>
                    ))}
                </ul>

                <div className="mt-5 mb-3">
                    <h4>Pagamento</h4>

{ paymentStatus === 'approved'?
                <div >
                    <Lottie options={defaultOptions}
                    height={400}
                    width={400}
                    isStopped={animationState.isStopped}
                    isPaused={animationState.isPaused}/>
                </div> :
                <>
                     <div className="btn-group" role="group2" aria-label="Opções de pagamento" 
                      onClick={() => {
                        if (!endereco) {
                            setAvisoEndereco(true);
                        } else {
                            setAvisoEndereco(false);
                        }
                    }}>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="metodoPagamento"
                                onClick={() => {TipoPagAtualizar('cartao')}}
                                disabled={endereco.length === 0} // Desabilita até que um endereço seja selecionado
                            />
                            <label className="form-check-label me-3 ms-2" >
                                <b>Cartão</b>
                            </label>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="metodoPagamento"
                                onClick={() => {TipoPagAtualizar('pix')}}
                                disabled={endereco.length === 0} // Desabilita até que um endereço seja selecionado
                            />
                            <label className="form-check-label me-4 ms-2" >
                                <b>Pix</b>
                            </label>
                            <input
                                className="form-check-input"
                                type="radio"
                                name="metodoPagamento"
                                onClick={() => {TipoPagAtualizar('dinheiro')}}
                                disabled={endereco.length === 0} // Desabilita até que um endereço seja selecionado
                            />
                            <label className="form-check-label ms-2" >
                                <b>Dinheiro</b>
                            </label>
                    </div>
                        
                    {avisoEndereco && (
                            <div className="alert alert-warning mt-2" role="alert">
                                Selecione um endereço antes de escolher a forma de pagamento.
                            </div>)
                    }
                   
                    <div className="payment-container mt-3">
                        {tipo_pag === 'cartao' &&  <Cartao
                                                        id_pagador={localStorage.getItem('sessionId')}
                                                        id_estabelecimento={localStorage.getItem('sessionIdEstabelecimento')} 
                                                        amount={totalCart} 
                                                        itens= {cart}
                                                        description={localStorage.getItem('sessionNomeEstabelecimento')}
                                                        statement_descriptor={localStorage.getItem('sessionNomeEstabelecimento')}
                                                        first_name={localStorage.getItem('sessionNome')?localStorage.getItem('sessionNome').split(' ')[0] :''}
                                                        last_name={localStorage.getItem('sessionNome')?localStorage.getItem('sessionNome').split(' ')[1] : ''}
                                                        area_code={localStorage.getItem('sessionFone')?localStorage.getItem('sessionFone').slice(0,2) : ''}
                                                        number ={localStorage.getItem('sessionFone')?localStorage.getItem('sessionFone').slice(2) : ''}
                                                        address={localStorage.getItem('sessionEndereco')}
                                                        zip_code={localStorage.getItem('sessionCep')}
                                                        street_name={localStorage.getItem('sessionEndereco')}
                                                        street_number={localStorage.getItem('sessionNumero')}
                                                        email={localStorage.getItem('sessionEmail')} 
                                                        identificationType={''} 
                                                        identificationNumber={''} 
                                                        external_reference={uuidv4()}
                                                        onPaymentStatusChange={handlePaymentStatusChange}
                                                        onPaymentIDChange={handlePaymentIDChange}                        
                                                    />}
                        {tipo_pag === 'pix' && <Pix
                                                        id_pagador={localStorage.getItem('sessionId')}
                                                        id_estabelecimento={localStorage.getItem('sessionIdEstabelecimento')}                                                   
                                                        amount={totalCart} 
                                                        first_name={localStorage.getItem('sessionNome')?localStorage.getItem('sessionNome').split(' ')[0] : ''}
                                                        last_name={localStorage.getItem('sessionNome')?localStorage.getItem('sessionNome').split(' ')[1] : ''}                                                   
                                                        email={localStorage.getItem('sessionEmail')} 
                                                        description={localStorage.getItem('sessionNomeEstabelecimento')}
                                                        statement_descriptor={localStorage.getItem('sessionNomeEstabelecimento')}
                                                        identificationType={''} 
                                                        identificationNumber={''} 
                                                        onPaymentStatusChange={handlePaymentStatusChange}
                                                        onPaymentIDChange={handlePaymentIDChange}
                                                />}
                        {tipo_pag === 'dinheiro' && <Dinheiro
                                                        valorTotal={totalCart}
                                                        onTrocoChange={handleTrocoChange}
                                                        onVisibleFinalizar={handleVisibleFinalizar}                                                        
                                                        onPaymentIDChange={handlePaymentIDChange}
                                                    />}
                    </div>
                </>}
            </div>
            {paymentStatus === "approved" && <p className="sucess"><b>Pagamento aprovado com sucesso!</b></p>}

                <div className="row mb-5">
                <button 
                        onClick={FinalizarPedido} 
                        className="btn btn-lg btn-danger mt-4" 
                        disabled={VisibleFinalizar !== true}>
                     Finalizar Pedido
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Checkout;
