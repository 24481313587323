import { useContext, useEffect, useState } from "react";
import Modal from 'react-modal/lib/components/Modal';
import closeIcone from '../../../assets/close.png';
import ProdutoItemCheckbox from "../produto-item-checkbox";
import ProdutoItemRadio from "../produto-item-radio";
import api from '../../../services/api';
import './style.css';
import { CartContext } from "../../../contexts/cart";
import {v4 as uuidv4} from 'uuid';
import LoadingDots from "../../loading_dots";

function ProdutoModal(props){

    const {cart, AddItemCart} = useContext(CartContext);
    const [id_produto, setId_produto] = useState(0);
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');
    const [vl_produto, setVl_produto] = useState(0);
    const [vl_promocao, setVl_promocao] = useState(0);
    const [url_foto, setUrl_foto] = useState('');
    const [qtd, setQtd] = useState(1);
    const [qtd_max_escolha, setQtd_max_escolha] = useState(1);
    const [opcoes, setOpcoes] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [bloquearBtn, setBloquearBtn] = useState(true);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (props.id_produto <= 0){
            return;
        }
        setLoading(true)
        api.get(`v1/produtos/${props.id_produto}`)
            .then(response => {

                setDescricao(response.data.descricao);
                setId_produto(props.id_produto);
                setNome(response.data.nome);
                setQtd(1);
                setUrl_foto(response.data.url_foto);
                setVl_produto(response.data.vl_produto);
                setVl_promocao(response.data.vl_promocao);


                setTotal(response.data.vl_promocao > 0 ? response.data.vl_promocao : response.data.vl_produto);
            })
            .catch(err => console.log(err));

        api.get(`v1/cardapios/opcoes/${props.id_produto}`)
            .then(response => {
                setOpcoes(response.data);

                let gruposUnico = response.data.map(g => {
                    setQtd_max_escolha(g.qtd_max_escolha);
                    return {
                        id_opcao: g.id_opcao,
                        id_produto: g.id_produto,
                        descricao: g.descricao,
                        ind_obrigatorio: g.ind_obrigatorio,
                        qtd_max_escolha: g.qtd_max_escolha,
                        ind_ativo: g.ind_ativo,
                        ordem: g.ordem,
                        selecao: []
                    };
                });

                gruposUnico = gruposUnico.filter((item, index, arr) => {
                    return arr.findIndex((t) => {
                        return t.id_opcao === item.id_opcao;
                    }) === index;
                });

                setGrupos(gruposUnico);
                HabilitaBotao(gruposUnico);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });

    }, [props.isOpen]);

    function ClickMais(){
        setQtd(qtd + 1);
    }

    function ClickMenos(){
        qtd > 1 ? setQtd(qtd - 1) : setQtd(1);
    }

    function AddItem() {
        let detalhes = [];
        let vl_detalhes = 0;

        grupos.forEach(item => {
            item.selecao.forEach(sel => {
                vl_detalhes += parseFloat(sel.vl_item); // Convertendo vl_item para número

                detalhes.push({
                    nome: sel.nome,
                    id_item: sel.id_item,
                    vl_item: parseFloat(sel.vl_item), // Garantindo que vl_item seja numérico
                    ordem: sel.ordem
                });
            });

        });


        const item = {
            id_carrinho: uuidv4(),
            id_produto: id_produto,
            nome: nome,
            descricao: nome,
            qtd: parseInt(qtd, 10),  // Convertendo qtd para número inteiro
            url_foto: url_foto,
            vl_unit: parseFloat(vl_detalhes) + (parseFloat(vl_promocao) > 0 ? parseFloat(vl_promocao) : parseFloat(vl_produto)),
            vl_total: (parseFloat(vl_detalhes) + (parseFloat(vl_promocao) > 0 ? parseFloat(vl_promocao) : parseFloat(vl_produto))) * parseFloat(qtd),

            detalhes: detalhes
        };

        AddItemCart(item);
        props.onRequestClose();
    }

    function SelecionaRadioButton(op){
        let g = grupos;

        // Descobrir o indice do grupo clicado...
        let objIndex = g.findIndex(obj => obj.id_opcao === op.id_opcao);

        // Atualizar informacao do item naquele indice...
        g[objIndex].selecao = [op];

        setGrupos(g);
        HabilitaBotao(g);
        CalculaTotal(g);
    }

    function SelecionaCheckbox(isChecked, op){
        let g = grupos;
        let s = [];
        let objIndex = g.findIndex(obj => obj.id_opcao === op.id_opcao);
        s = g[objIndex].selecao;
    
        if (isChecked){
                s.push(op);
        } else {
            let objIndexSel = s.findIndex(obj => obj.id_item === op.id_item);
            s.splice(objIndexSel, 1);
        }
    
        g[objIndex].selecao = s;
        setGrupos(g);
        HabilitaBotao(g);
        CalculaTotal(g);        
    }

    function HabilitaBotao(grp){
        let bloquear = false;

        grp.map(item => {
            if (item.ind_obrigatorio === "S" && item.selecao.length === 0) {
                bloquear = true;
            }
        });

        setBloquearBtn(bloquear);
    }

    function CalculaTotal(grp) {
        let vl_selecao = 0;
        let vl_prod = vl_promocao > 0 ? vl_promocao : vl_produto;

        grp.forEach(item => {
            item.selecao.forEach(sel => {
                vl_selecao += parseFloat(sel.vl_item);
            });
        });

        setTotal(vl_selecao + vl_prod * qtd);
    }

    useEffect(() => {
        CalculaTotal(grupos);
    }, [qtd]);

    return <>
        {loading ? (
            <LoadingDots />
        ) : (
            <>
                <Modal isOpen={props.isOpen}
                    onRequestClose={props.onRequestClose}
                    overlayClassName="react-modal-overlay"
                    className="react-modal-content">

                    <button type="button" onClick={props.onRequestClose} className="react-modal-close">
                        <img src={closeIcone} alt="Fechar" />
                    </button>

                    <div className="container-fluid h-100 produto-modal">
                        <div className="row detalhes-produto">
                            <div>
                                <img className="img-fluid rounded img-produto-modal"
                                    src={url_foto}
                                    alt="Produto" />
                            </div>

                            <div className="col-12 mt-4">
                                <h4 className="mt-2">{nome}</h4>
                                <small className="d-block mb-3">
                                    {descricao}
                                </small>

                                {
                                    vl_promocao > 0 ? <>
                                        <small className="mt-3 promocao">
                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(vl_promocao)}
                                        </small>
                                        <small className="ms-4 mt-3 preco-antigo">
                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(vl_produto)}
                                        </small></>
                                        :
                                        <small className="mt-3">
                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(vl_produto)}
                                        </small>
                                }
                            </div>

                            <div className="col-12 mb-4">
                                {
                                    grupos.map(grupo => {

                                        let op = opcoes.filter((item, index, arr) => {
                                            return item.id_opcao === grupo.id_opcao
                                        });

                                        return grupo.qtd_max_escolha === 1 ?
                                            <ProdutoItemRadio key={grupo.id_opcao}
                                                titulo={grupo.descricao}
                                                obrigatorio={grupo.ind_obrigatorio === "S" ? true : false}
                                                opcoes={op}
                                                onClickItem={SelecionaRadioButton}
                                            />
                                            :
                                            <ProdutoItemCheckbox 
                                                key={grupo.id_opcao}
                                                titulo={grupo.descricao}
                                                opcoes={op}
                                                qtd_max_escolha={grupo.qtd_max_escolha} // Passa o limite máximo permitido
                                                onClickItem={SelecionaCheckbox} 
                                            />
                                    })
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 mt-3 d-flex justify-content-end">
                                <div>
                                    <button onClick={ClickMenos} className="btn btn-outline-danger mt-1"><i className="fas fa-minus"></i></button>
                                    <span className="m-3 button-qtd">{qtd.toLocaleString('pt-BR', {
                                        minimumIntegerDigits: 2
                                    })}</span>
                                    <button onClick={ClickMais} className="btn btn-outline-danger mt-1"><i className="fas fa-plus"></i></button>

                                    <button onClick={AddItem} className="btn btn-danger ms-4 mt-1" disabled={bloquearBtn}>Adicionar a sacola (
                                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total)}
                                    )</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal></>
        )};
    </>
}

export default ProdutoModal;