import { useState } from "react";
import './style.css';

function Dinheiro(props) {
    const [dinheiroPago, setDinheiroPago] = useState('');
    const [troco, setTroco] = useState(0);
    const [solicitouTroco, setSolicitouTroco] = useState(false);

    const calcularTroco = () => {
        const valorPago = parseFloat(dinheiroPago);
        const valorTotal = parseFloat(props.valorTotal);              
    
        if (!isNaN(valorPago) && !isNaN(valorTotal)) {
            const vl = (valorPago - valorTotal);
    
            if (valorPago < valorTotal) { 
                setTroco(0);
                props.onVisibleFinalizar(false);
                alert("Valor pago é menor que o valor total.");
            } else {            
                setTroco(vl);
                props.onPaymentIDChange(0)
                props.onVisibleFinalizar(true);
                props.troco(vl); // Passando o troco correto
            } 
        }    
    };

    const solicitarTroco = (resposta) => {
        if (resposta === 'sim') {
            setSolicitouTroco(true);
            props.onVisibleFinalizar(false);
        } else {            
            setTroco(0);
            props.onPaymentIDChange(0)
            props.onVisibleFinalizar(true);
            props.onTrocoChange(troco); //
        }
    };

    if (!solicitouTroco) {
        return (
            <div>
                <p>Deseja solicitar troco?</p>
                <button className="btn btn-success mt-2 me-3" onClick={() => solicitarTroco('sim')}>
                    Sim
                </button>
                <button className="btn btn-danger mt-2 " onClick={() => solicitarTroco('não')}>
                    Não
                </button>
            </div>
        );
    }

    return (
        <>
            <div className="form-control" aria-label="Search">
                <input
                    className="text-center border border-light"
                    type="text"
                    placeholder="Seu Dinheiro"
                    value={dinheiroPago}
                    onChange={(e) => setDinheiroPago(e.target.value)}
                />
                <small className="m-1">Coloque o quanto vai pagar</small>
            </div>
            <button className="btn btn-primary mt-2" onClick={calcularTroco}>Calcular Troco</button>
            {troco !== 0 && (
                <small className="a-auto mt-2" style={{ color: '#00b894' }}>
                    <b className="m-5">Seu troco: R$ {troco.toFixed(2)}</b>
                </small>
            )}
        </>
    );
}

export default Dinheiro;