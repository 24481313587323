import { useState, useEffect } from "react";

function ProdutoItemCheckbox(props) {
    const [selecionados, setSelecionados] = useState([]);

    useEffect(() => {
        // Atualiza a lista de seleção ao mudar as opções
        setSelecionados([]);
    }, [props.opcoes]);

    function handleCheckboxChange(isChecked, opcao) {
        let novaSelecao = [...selecionados];

        if (isChecked) {
            if (novaSelecao.length < props.qtd_max_escolha) {
                novaSelecao.push(opcao);
            }
        } else {
            novaSelecao = novaSelecao.filter(item => item.id_item !== opcao.id_item);
        }

        setSelecionados(novaSelecao);
        props.onClickItem(isChecked, opcao);
    }

    return (
        <div className="card mt-4">
            <div className="card-header d-flex justify-content-between">
                {props.titulo}
            </div>

            <ul className="list-group list-group-flush">
                {props.opcoes.map(opcao => {
                    const isChecked = selecionados.some(item => item.id_item === opcao.id_item);
                    const isDisabled = !isChecked && selecionados.length >= props.qtd_max_escolha;

                    return (
                        <li className="list-group-item d-flex justify-content-between" key={opcao.id_item}>
                            <div>
                                <input 
                                    className="form-check-input" 
                                    type="checkbox" 
                                    id={`flexCheckDefault${opcao.id_item}`} 
                                    checked={isChecked} 
                                    disabled={isDisabled} 
                                    onChange={(e) => handleCheckboxChange(e.target.checked, {
                                        id_opcao: opcao.id_opcao,
                                        nome: opcao.nome_item,
                                        id_item: opcao.id_item,
                                        vl_item: opcao.vl_item,
                                        ordem: opcao.ordem
                                    })}
                                />
                                <label className="form-check-label ms-2" htmlFor={`flexCheckDefault${opcao.id_item}`}>
                                    {opcao.nome_item}
                                </label>
                            </div>
                            <div>
                                {opcao.vl_item > 0 && (
                                    <span className="text-danger">
                                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(opcao.vl_item)}
                                    </span>
                                )}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default ProdutoItemCheckbox;
