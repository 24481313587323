import { useEffect, useState } from "react";
import { loadMercadoPago } from "@mercadopago/sdk-js";
import api from "../../../services/api";
import "./pix.css";

function Pix(props) {
  const [qrCodeBase64, setQrCodeBase64] = useState(null);
  const [qrCode, setQrCode] = useState("");
  const [deviceId, setDeviceId] = useState(null);

  const copiarParaClipboard = () => {
    if (qrCode) {
        navigator.clipboard.writeText(qrCode);        
        alert('Link copiado para a área de transferência!');
    }
};

  useEffect(() => {
    const loadDeviceId = () => {
      const script = document.createElement("script");
      script.src = `https://www.mercadopago.com/v2/security.js`;
      script.setAttribute("view", "checkout");
      script.setAttribute("output","deviceId");
      document.body.appendChild(script);
  
      setTimeout(() => {
        const deviceIdInput = document.getElementById('deviceId');
    if (deviceIdInput) {
      localStorage.setItem('deviceId', deviceIdInput.value);
    }       
      }, 5000); // Aguarda 5s para garantir que o script carregou
    };
  
    loadDeviceId();  
    return () => {
      const scripts = document.querySelectorAll('script[src*="mercadopago.com/v2/security.js"]');
      scripts.forEach((script) => script.remove()); // Remove o script ao desmontar
    };

  }, []);    

  const processarPagamento = async (event) => {
    event.preventDefault(); // Impede o redirecionamento do formulário

    const paymentData = {
      transaction_amount: props.amount,
      description: props.description,
      statement_descriptor: props.statement_descriptor,
      payment_method_id: "pix",
      payer: {
        first_name: document.getElementById("form-checkout__payerFirstName").value,
        last_name: document.getElementById("form-checkout__payerLastName").value,
        email: document.getElementById("form-checkout__email").value,
        identification: {
          type: document.getElementById("form-checkout__identificationType").value,
          number: document.getElementById("form-checkout__identificationNumber").value,
        },
      },
      additional_info: {
        payer: {
          first_name: props.first_name,
          last_name: props.last_name,
        },
      },
      notification_url: process.env.REACT_APP_NOTIFICATION_URL,
      id_pagador: props.id_pagador,
      id_estabelecimento: props.id_estabelecimento,
    };

    try {
      const response = await api.post("/v1/process_payment", paymentData, {
        headers: { "X-meli-session-id": localStorage.getItem('deviceId') }, // Agora está correto
      });

      if (response.data.status === "pending") {
        const qrCodeBase64 = response.data.point_of_interaction?.transaction_data?.qr_code_base64;
        const qrCode = response.data.point_of_interaction?.transaction_data?.qr_code;
        const id_pagamento = response.data.id;
        props.onPaymentIDChange(response.data.id);

        if (qrCodeBase64) {
          setQrCodeBase64(qrCodeBase64);
          setQrCode(qrCode);
          alert("Escaneie o QR Code para concluir.");

          // Configura um intervalo para checar o status do pagamento
          const intervalId = setInterval(async () => {
            const statusResponse = await api.get(`/v1/payment_status/${id_pagamento}`);

            if (statusResponse.data.status === "approved") {
              clearInterval(intervalId); // Para o intervalo quando o pagamento for aprovado
              props.onPaymentStatusChange("approved");
            }
          }, 5000); // Verifica a cada 5 segundos
        }
      }
    } catch (error) {
      console.error("Erro ao processar o pagamento:", error);
    }
  };

  useEffect(() => {
    const initMercadoPago = async () => {
      await loadMercadoPago();
      const mp = new window.MercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY);
    };
    initMercadoPago();    
  }, []);  

  return (
       <>
          <input type="hidden" id="deviceId" value={deviceId}></input>    
      {!qrCodeBase64 && (
        <form id="form-checkout" onSubmit={processarPagamento}>
          <div>
            <label htmlFor="payerFirstName">Nome</label>
            <input id="form-checkout__payerFirstName" name="payerFirstName" type="text" defaultValue={props.first_name} />

            <label htmlFor="payerLastName">Sobrenome</label>
            <input id="form-checkout__payerLastName" name="payerLastName" type="text" defaultValue={props.last_name} />

            <label htmlFor="email">E-mail</label>
            <input id="form-checkout__email" name="email" type="text" defaultValue={props.email} />

            <label htmlFor="identificationType">Tipo de documento</label>
            <select id="form-checkout__identificationType" name="identificationType" defaultValue={props.identificationType}>
              <option value="CPF">CPF</option>
              <option value="CNPJ">CNPJ</option>
            </select>

            <label htmlFor="identificationNumber">Número do documento</label>
            <input id="form-checkout__identificationNumber" name="identificationNumber" type="text" defaultValue={props.identificationNumber} />
          </div>
        
          <button type="submit">Pagar com PIX</button>
          </form>           
      )}

      {qrCodeBase64 && (
        <div className="qr-code-section">
          <img src={`data:image/png;base64,${qrCodeBase64}`} alt="QR Code para pagamento" />
          <button onClick={copiarParaClipboard}>Copiar QR Code</button>
        </div>
      )}
     
    </>
  );
}

export default Pix;
